import { createSlice } from "@reduxjs/toolkit";
import { Team } from "../../interfaces/team/Team";
import { Listing } from "../../interfaces/training-plan/Listing";

interface ITeamSlice {
  teamData: Team;
  loading: boolean;
  teamListings: Listing[];
  tabBarValue: number;
}

const initialState: ITeamSlice = {
  teamData: null,
  loading: true,
  teamListings: [],
  tabBarValue: 0,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setCurrentTeam: (
      state,
      action: { payload: { team: Team; listings?: Listing[] } },
    ) => {
      state.teamData = action.payload.team;
      state.teamListings = action.payload.listings;
      state.loading = false;
    },
    setTabBarValue: (state, action: { payload: number }) => {
      state.tabBarValue = action.payload;
    },
  },
});

export const { setCurrentTeam, setTabBarValue } = teamSlice.actions;
export default teamSlice.reducer;
