import { configureStore } from "@reduxjs/toolkit";

import objectivesReducer from "./objectivesSlice";
import sportsReducer from "./sportsSlice";
import profileReducer from "./profileSlice";
import blogsReducer from "./blogsSlice";
import loginReducer from "./loginSlice";
import registerReducer from "./registerSlice";
import forgotPasswordReducer from "./forgotPasswordSlice";
import confirmPopupSlice from "./confirmPopupSlice";
import categoryFilterSlice from "./categoryFilterSlice";
import searchSlice from "./searchSlice";
import injurySlice from "./injurySlice";
import bodyAreasSlice from "./bodyAreasSlice";
import menuSlice from "./menuSlice";
import trainingPlanSlice from "./pages/trainingPlanSlice";
import themeSlice from "./themeSlice";
import filtersSlice from "./filtersSlice";
import userSlice from "./userSlice";
import exploreSlice from "./pages/exploreSlice";
import specialistSlice from "./pages/specialistSlice";
import teamSlice from "./pages/teamSlice";
import loginPageSlice from "./pages/loginPageSlice";
import registerPageSlice from "./pages/registerPageSlice";
import forgotPasswordPageSlice from "./pages/forgotPasswordPageSlice";
import stripePricesSlice from "./stripePricesSlice";
import onboardingSlice from "./onboardingSlice";
import globalSlice from "./globalSlice";
import paymentSlice from "./pages/paymentSlice";
import librarySlice from "./pages/librarySlice";
import loginServiceSlice from "./loginServiceSlice";

const store = configureStore({
  reducer: {
    objectives: objectivesReducer,
    sports: sportsReducer,
    profile: profileReducer,
    blogs: blogsReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    register: registerReducer,
    confirm: confirmPopupSlice,
    categories: categoryFilterSlice,
    search: searchSlice,
    injury: injurySlice,
    bodyAreas: bodyAreasSlice,
    menu: menuSlice,
    trainingPlan: trainingPlanSlice,
    specialist: specialistSlice,
    theme: themeSlice,
    filters: filtersSlice,
    user: userSlice,
    explore: exploreSlice,
    team: teamSlice,
    loginPage: loginPageSlice,
    registerPage: registerPageSlice,
    forgotPasswordPage: forgotPasswordPageSlice,
    stripePrices: stripePricesSlice,
    onboardingSlice: onboardingSlice,
    global: globalSlice,
    library: librarySlice,
    paymentPage: paymentSlice,
    loginService: loginServiceSlice,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
