import { createSlice } from "@reduxjs/toolkit";
import React from "react";

export interface IGlobalSlice {
  snackbar: {
    showSnackbar: boolean;
    snackbarText?: string;
    component: React.ReactNode;
    errorComponent: React.ReactNode;
  };
}

const initialState: IGlobalSlice = {
  snackbar: {
    showSnackbar: false,
    snackbarText: "",
    component: null,
    errorComponent: null,
  },
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    setShowSnackbar: (state, action: { payload: boolean }) => {
      state.snackbar.showSnackbar = action.payload;
    },
    setSnackbarText: (state, action: { payload: string }) => {
      state.snackbar.snackbarText = action.payload;
      state.snackbar.showSnackbar = true;
    },
    setCurrentSnackbar: (state, action: { payload: React.ReactNode }) => {
      state.snackbar.component = action.payload;
    },
    setCurrentErrorSnackbar: (state, action: { payload: React.ReactNode }) => {
      state.snackbar.errorComponent = action.payload;
    },
  },
});

export const {
  setShowSnackbar,
  setSnackbarText,
  setCurrentErrorSnackbar,
  setCurrentSnackbar,
} = globalSlice.actions;
export default globalSlice.reducer;
