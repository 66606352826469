import { createSlice } from "@reduxjs/toolkit";
import {
  IOnboardingDetails,
  IOnboardingSlice,
} from "../interfaces/onboarding/redux/IOnboardingSlice";
import { IUserData } from "../interfaces/user/IUserData";
import { ISport } from "../interfaces/globalComponents/filters/filterItems/ISport";
import { IObjective } from "../interfaces/globalComponents/filters/filterItems/IObjective";
import { EquipmentFromApi } from "../interfaces/onboarding/EquipmentFromApi";
import { BodyHistoryItem } from "../interfaces/onboarding/injuries/BodyHistoryItem";
import { MuscleArea } from "../interfaces/onboarding/injuries/BodyInjury";

export const initialState: IOnboardingSlice = {
  isOpenOnboardingModal: false,
  currentTitle: "Select your sports",
  currentSubtitle: "Select all options that apply to you",
  step: 1,
  isLoading: true,
  userData: undefined,
  sports: [],
  objectives: [],
  equipments: [],
  timezones: [],
  isAllEquipmentsSelected: false,
  details: {
    dob: undefined,
    gender: undefined,
    dateFormat: undefined,
    units: undefined,
    timezone: undefined,
    level: undefined,
    height: undefined,
    weight: undefined,
  },
  injuries: [],
  injuriesToBeDeleted: [],
  bodyAreas: [],
  showFrontInjuryImage: true,
  showAddInjuryModal: false,
  showCancelOnboardingModal: false,
  showConfirmPasswordReset: false,
  isEditing: false,
  name: undefined,
  email: undefined,
  password: undefined,
};

const updateCurrentOnboardingSectionInfo = (state: IOnboardingSlice) => {
  switch (state.step) {
    case 1:
      state.currentTitle = "Select your sports";
      state.currentSubtitle = "Select all options that apply to you";
      break;
    case 2:
      state.currentTitle = "Choose your objectives";
      state.currentSubtitle = "Select all options that apply to you";
      break;
    case 3:
      state.currentTitle = "What equipment do you have available?";
      state.currentSubtitle = "Select all options that you have access to";
      break;
    case 4:
      state.currentTitle = "Do you have any injuries?";
      state.currentSubtitle =
        "Click on ‘Log Injury’ to register current or previous injuries";
      break;
    case 5:
      state.currentTitle = "Provide your profile details";
      state.currentSubtitle = "";
      break;
    case 6:
      state.currentTitle = "Add your profile image";
      state.currentSubtitle = "";
      break;
  }
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setIsOpenOnboardingModal: (state, action: { payload: boolean }) => {
      state.isOpenOnboardingModal = action.payload;
    },
    setCurrentTitle: (state, action: { payload: string }) => {
      state.currentTitle = action.payload;
    },
    setCurrentSubtitle: (state, action: { payload: string }) => {
      state.currentSubtitle = action.payload;
    },
    setCurrentStep: (state, action: { payload: number }) => {
      state.step = action.payload;
    },
    increaseCurrentStep: (state) => {
      if (state.step === 6) {
        setIsOpenOnboardingModal(false);
        return;
      }
      state.step = ++state.step;
      updateCurrentOnboardingSectionInfo(state);
    },
    decreaseCurrentStep: (state) => {
      state.step = --state.step;
      updateCurrentOnboardingSectionInfo(state);
    },
    handleSport: (state, action: { payload: ISport }) => {
      state.sports = state.sports.map((sport) => {
        if (sport.id === action.payload.id) {
          const updatedData = { ...sport, isSelected: !sport.isSelected };
          return updatedData;
        }
        return sport;
      });
    },
    handleObjective: (state, action: { payload: IObjective }) => {
      state.objectives = state.objectives.map((objective) => {
        if (objective.id === action.payload.id) {
          const updatedData = {
            ...objective,
            isSelected: !objective.isSelected,
          };
          return updatedData;
        }
        return objective;
      });
    },
    handleEquipment: (state, action: { payload: EquipmentFromApi }) => {
      state.equipments = state.equipments.map((equipment) => {
        if (equipment.id === action.payload.id) {
          const updatedData = {
            ...equipment,
            isSelected: !equipment.isSelected,
          };
          return updatedData;
        }
        return equipment;
      });
    },
    handleSelectAllEquipments: (state) => {
      state.equipments = state.equipments.map((equipment) => {
        const updatedData = {
          ...equipment,
          isSelected: equipment.isSelected ? equipment.isSelected : true,
        };
        return updatedData;
      });
      state.isAllEquipmentsSelected = true;
    },
    handleUnselectAllEquipments: (state) => {
      state.equipments = state.equipments.map((equipment) => {
        const updatedData = {
          ...equipment,
          isSelected: !equipment.isSelected ? equipment.isSelected : false,
        };
        return updatedData;
      });
      state.isAllEquipmentsSelected = false;
    },
    setIsLoading: (state, action: { payload: boolean }) => {
      state.isLoading = action.payload;
    },
    setUserData: (state, action: { payload: IUserData }) => {
      state.userData = action.payload;
    },
    setSports: (state, action: { payload: ISport[] }) => {
      state.sports = action.payload;
    },
    setObjectives: (state, action: { payload: IObjective[] }) => {
      state.objectives = action.payload;
    },
    setEquipments: (state, action: { payload: EquipmentFromApi[] }) => {
      state.equipments = action.payload;
    },
    setDateOfBirth: (state, action: { payload: string }) => {
      state.details.dob = action.payload;
    },
    setTimezones: (state, action: { payload: string[] }) => {
      state.timezones = action.payload;
    },
    setDateFormat: (
      state,
      action: { payload: "DD/MM/YYYY" | "MM/DD/YYYY" },
    ) => {
      state.details.dateFormat = action.payload;
    },
    setGender: (state, action: { payload: string }) => {
      state.details.gender = action.payload;
    },
    setWeight: (state, action: { payload: number }) => {
      if (action.payload > 0) {
        state.details.weight = action.payload;
      } else {
        state.details.weight = null;
      }
    },
    setHeight: (state, action: { payload: number }) => {
      if (action.payload > 0) {
        state.details.height = action.payload;
      } else {
        state.details.height = null;
      }
    },
    setLevel: (state, action: { payload: string }) => {
      state.details.level = action.payload;
    },
    setUnit: (state, action: { payload: string }) => {
      state.details.units = action.payload;
    },
    setUserTimezone: (state, action: { payload: string }) => {
      state.details.timezone = action.payload;
    },
    setFullDetails: (state, action: { payload: IOnboardingDetails }) => {
      state.details = action.payload;
    },
    setUserInjuries: (state, action: { payload: BodyHistoryItem[] }) => {
      state.injuries = action.payload;
    },
    invertShowFrontInjuryImage: (state) => {
      state.showFrontInjuryImage = !state.showFrontInjuryImage;
    },
    setBodyAreas: (state, action: { payload: MuscleArea[] }) => {
      state.bodyAreas = action.payload;
    },
    addInjuryToBeDeleted: (state, action: { payload: BodyHistoryItem }) => {
      state.injuriesToBeDeleted = [
        ...state.injuriesToBeDeleted,
        action.payload,
      ];
    },
    setShowAddInjuryModal: (state, action: { payload: boolean }) => {
      state.showAddInjuryModal = action.payload;
    },
    setShowCancelModal: (state, action: { payload: boolean }) => {
      state.showCancelOnboardingModal = action.payload;
    },
    setStep: (state, action: { payload: number }) => {
      state.step = action.payload;
      updateCurrentOnboardingSectionInfo(state);
    },
    setIsEditing: (state, action: { payload: boolean }) => {
      state.isEditing = action.payload;
    },
    setEmail: (state, action: { payload: string }) => {
      state.email = action.payload;
    },
    setName: (state, action: { payload: string }) => {
      state.name = action.payload;
    },
    setShowConfirmPasswordReset: (state, action: { payload: boolean }) => {
      state.showConfirmPasswordReset = action.payload;
    },
    setPassword: (state, action: { payload: string }) => {
      state.password = action.payload;
    },
    clearOnboarding: (state) => {
      state.details = initialState.details;
    },
  },
});

export const {
  setIsOpenOnboardingModal,
  setCurrentTitle,
  setCurrentStep,
  setCurrentSubtitle,
  increaseCurrentStep,
  setUserData,
  setIsLoading,
  decreaseCurrentStep,
  handleSport,
  setSports,
  setObjectives,
  handleObjective,
  setEquipments,
  handleEquipment,
  handleSelectAllEquipments,
  handleUnselectAllEquipments,
  setDateOfBirth,
  setTimezones,
  setDateFormat,
  setGender,
  setHeight,
  setWeight,
  setLevel,
  setUnit,
  setUserTimezone,
  setFullDetails,
  setUserInjuries,
  invertShowFrontInjuryImage,
  setBodyAreas,
  setShowAddInjuryModal,
  addInjuryToBeDeleted,
  setShowCancelModal,
  setStep,
  setIsEditing,
  setName,
  setEmail,
  setShowConfirmPasswordReset,
  setPassword,
  clearOnboarding,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
