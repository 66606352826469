import { createSlice } from "@reduxjs/toolkit";
import { Listing } from "../../interfaces/training-plan/Listing";
import { IUserData } from "../../interfaces/user/IUserData";
import { ITrainingPlanReview } from "../../interfaces/training-plan/ITrainingPlanReview";
import { ListingsFromApi } from "../../interfaces/training-plan/ListingsFromApi";
import { ISwapsAPI } from "../../interfaces/training-plan/ISwapsAPI";
import { ListingType } from "../../enums/listing/TitleTypes";
import { ILibraryListing } from "../../interfaces/training-plan/ILibraryListing";
import { PriceFromStripe } from "../../interfaces/apiRequests/PriceFromStripe";
import { EquipmentFromListing } from "../../interfaces/training-plan/EquipmentFromListing";

interface ITrainingPlanSlice {
  listingData: Listing | null;
  isFollowingCoach: boolean | undefined;
  isLoadingFollowBtn: boolean;
  reviews: ITrainingPlanReview[];
  similarListings: ListingsFromApi;
  loading: boolean;
  loadingLoginListingModal: boolean;
  errorLoginListingModal: IUserData["errors"];
  isLoadingPurchaseOptions: boolean;
  isLoadingListingModal: boolean;
  gotSubscription: boolean;
  swaps: ISwapsAPI;
  isSavedListing: boolean;
  listingType: ListingType;
  borrowedListings: ILibraryListing[];
  ownedListings: Listing[];
  userFullSubscriptionData: PriceFromStripe;
  listingEquipments: EquipmentFromListing[];
}

const initialState = {
  isFollowingCoach: undefined,
  isLoadingFollowBtn: true,
  listingData: null,
  similarListings: null,
  reviews: null,
  loading: true,
  loadingLoginListingModal: false,
  errorLoginListingModal: null,
  isLoadingPurchaseOptions: false,
  isLoadingListingModal: true,
  gotSubscription: false,
  swaps: null,
  isSavedListing: undefined,
  listingType: ListingType.TRAINING_PLAN,
  borrowedListings: [],
  ownedListings: [],
  userFullSubscriptionData: null,
} as ITrainingPlanSlice;

export const trainingPlanSlice = createSlice({
  name: "trainingPlan",
  initialState,
  reducers: {
    setCurrentListing: (state, action: { payload: Listing }) => {
      state.listingData = action.payload;
      state.loading = false;
    },
    setCurrentReviews: (state, action: { payload: ITrainingPlanReview[] }) => {
      state.reviews = action.payload;
    },
    setCurrentSimilarListings: (
      state,
      action: { payload: ListingsFromApi },
    ) => {
      state.similarListings = action.payload;
    },
    invertFollowingCoach: (state) => {
      state.isFollowingCoach = !state.isFollowingCoach;
    },
    invertLoadingFollowBtn: (state) => {
      state.isLoadingFollowBtn = !state.isLoadingFollowBtn;
    },
    setIsFollowingCoach: (state, action: { payload: boolean }) => {
      state.isFollowingCoach = action.payload;
    },
    setIsLoadingFollowBtn: (state, action: { payload: boolean }) => {
      state.isLoadingFollowBtn = action.payload;
    },
    setIsLoadingLoginListingModal: (state, action: { payload: boolean }) => {
      state.loadingLoginListingModal = action.payload;
    },
    setErrorLoginListingModal: (
      state,
      action: { payload: IUserData["errors"] },
    ) => {
      state.errorLoginListingModal = action.payload;
    },
    setIsLoadingListingModal: (state, action: { payload: boolean }) => {
      state.isLoadingListingModal = action.payload;
    },
    setGotSubscription: (state, action: { payload: boolean }) => {
      state.gotSubscription = action.payload;
    },
    setCurrentSwaps: (state, action: { payload: ISwapsAPI }) => {
      state.swaps = action.payload;
    },

    setIsSavedListing: (state, action: { payload: boolean }) => {
      state.isSavedListing = action.payload;
    },
    setListingType: (state, action: { payload: ListingType }) => {
      state.listingType = action.payload;
    },
    setBorrowedListings: (state, action: { payload: ILibraryListing[] }) => {
      state.borrowedListings = action.payload;
    },
    setUserFullSubscriptionData: (
      state,
      action: { payload: PriceFromStripe },
    ) => {
      state.userFullSubscriptionData = action.payload;
    },
    setOwnedListings: (state, action: { payload: Listing[] }) => {
      state.ownedListings = action.payload;
    },
    setEquipments: (state, action: { payload: EquipmentFromListing[] }) => {
      state.listingEquipments = action.payload;
    },
  },
});

export const {
  setIsFollowingCoach,
  setCurrentListing,
  setCurrentReviews,
  setCurrentSimilarListings,
  setIsLoadingLoginListingModal,
  setErrorLoginListingModal,
  setGotSubscription,
  setCurrentSwaps,
  setIsSavedListing,
  setListingType,
  setBorrowedListings,
  setUserFullSubscriptionData,
  setOwnedListings,
  setEquipments,
} = trainingPlanSlice.actions;

export default trainingPlanSlice.reducer;
