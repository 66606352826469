import { createSlice } from "@reduxjs/toolkit";
import { IUserData } from "../../interfaces/user/IUserData";
import { ListingsFromApi } from "../../interfaces/training-plan/ListingsFromApi";
import { TeamFeatured } from "../../interfaces/team/TeamFeatured";

export interface ISpecialistSlice {
  similarListings: ListingsFromApi;
  coachData: IUserData;
  loading: boolean;
  isFollowing: boolean;
  isOpen1to1Modal: boolean;
  tabBarValue: number;
  coachTeams: TeamFeatured[];
}

const initialState: ISpecialistSlice = {
  coachData: null,
  similarListings: null,
  loading: true,
  isFollowing: undefined,
  isOpen1to1Modal: false,
  tabBarValue: 0,
  coachTeams: [],
};

export const specialistSlice = createSlice({
  name: "specialistSlice",
  initialState,
  reducers: {
    setSpecialistPageInitialData: (
      state,
      action: {
        payload: {
          coachData: IUserData;
          similarListings: ListingsFromApi;
          coachTeams: TeamFeatured[];
        };
      },
    ) => {
      state.coachData = action.payload.coachData;
      state.similarListings = action.payload.similarListings;
      state.loading = false;
      state.coachTeams = action.payload.coachTeams;
    },
    setIsFollowing: (state, action: { payload: boolean }) => {
      state.isFollowing = action.payload;
    },
    setIsOpen1to1Modal: (state, action: { payload: boolean }) => {
      state.isOpen1to1Modal = action.payload;
    },
    setTabBarValue: (state, action: { payload: number }) => {
      state.tabBarValue = action.payload;
    },
  },
});

export const {
  setSpecialistPageInitialData,
  setIsFollowing,
  setIsOpen1to1Modal,
  setTabBarValue,
} = specialistSlice.actions;

export default specialistSlice.reducer;
